<template>
  <div
    :style="{
      background:
        style.background_type == 'image'
          ? `url(${style.background_value})`
          : '#ffffff'
    }"
    class="lenna-credit lenna-d-flex lenna-justify-content-center credit-shadow"
  >
    <div class="lenna-mr-2">
      <small @click="openLennaWeb()" class="lenna-pointer lenna-text-grey">
        Messaging by
      </small>
    </div>
    <div>
      <img
        @click="openLennaWeb()"
        :src="style.credit_image"
        alt="lenna-chat"
        @error="imgErrorHandler"
        class="lenna-pointer"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  },
  methods: {
    openLennaWeb() {
      window.open("https://lenna.ai", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.lenna-credit {
  // bottom: 60px;
  width: 100%;
  padding: 5px 0px;
}

.lenna-text-grey {
  color: #cacaca;
}

.credit-shadow {
  // box-shadow: 0px -15px 32px -12px rgba(0, 0, 0, 0.8);
  box-shadow: 0px -11px 33px -15px rgba(0, 0, 0, 0.4);
  position: sticky;
}

// .watermark {
//   position: fixed;
//   bottom: 5px;
//   right: 5px;
//   opacity: 0.5;
//   z-index: 99;
//   color: white;
// }
</style>
