<template>
  <div
    class="lenna-message lenna-d-flex"
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
  >
    <message-avatar :isSelf="isSelf" :message="message" />
    <div class="lenna-message-container">
      <message-head :isSelf="isSelf" :message="message" />
      <!-- <div class="lenna-message-content lenna-box-shadow"> -->
      <div
        class="lenna-message-content"
        style="max-width: 310px"
        :style="{
          'background-color': isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other
        }"
      >
        <div class="row lenna-d-flex">
          <div class="col-md-2">
            <img
              src="https://marketplace.canva.com/MAB5sssN0Qw/1/thumbnail_large/canva-file--MAB5sssN0Qw.png"
              alt
              class="lenna-img-fluid"
              width="32pt"
            />
          </div>
          <div class="col-md-8">
            <div>
              <a
                :href="content.fileUrl"
                :style="{ color: isSelf ? style.text_self : style.text_other }"
                target="_blank"
                >{{ content.fileName }}</a
              >
              <small
                :style="{ color: isSelf ? style.text_self : style.text_other }"
              >
                {{ content.fileSize }}
              </small>
            </div>

            <div>
              <a
                class="text-dark"
                :href="content.fileUrl"
                target="_blank"
                :style="{ color: isSelf ? style.text_self : style.text_other }"
                >Save File
              </a>
            </div>
          </div>
        </div>
        <message-footer :isSelf="isSelf" :message="message" />
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message-content {
  word-break: break-all;
  padding: 7px 10px;
  // color: $dark;
  // background: $light-gray;
  border-radius: 10px 10px 10px 0;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    // color: white;
    // background: $dark-blue;
    border-radius: 10px 10px 0 10px;
  }
}
.lenna-text-content {
  word-break: break-word;
  line-height: 16px;
  white-space: pre-line;
}
</style>
