<template>
  <div
    class="lenna-message lenna-d-flex"
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf, 'lenna-others-block': !isSelf, }"
    v-show="false"
  >
    <div
        :class="{
          'lenna-d-flex lenna-custom-header': !isSelf,
        }"
    >
      <message-avatar :isSelf="isSelf" :message="message" />
      <message-head :isSelf="isSelf" :message="message" />
    </div>
    <div class="lenna-message-container">
      <div class="lenna-message-content lenna-custom lenna-custom-summary-content">
        <table
          class="lenna-table lenna-bg-white summary-table lenna-box-shadow lenna-custom-table"
        >
          <thead>
            <tr>
              <th class="lenna-text-center" colspan="2">
                {{ content.title || "Summary" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in content.columns" :key="index">
              <td>{{ row.field }}</td>
              <td>{{ row.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
export default {
  mixins: [messageTypeMixin]
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.summary-table {
  width: 250px;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    color: white;
    background: $dark-blue;
  }
}
.lenna-custom-summary-content {
  background: #FFF !important;
  padding: 8px !important;
  max-width: 291px !important;
}
.lenna-custom-table {
  margin-bottom: 0 !important;
  box-shadow: none !important;
  border-collapse:separate !important;
  border-spacing: 3px 3px !important;
  width: 275px !important;
  thead {
    background: #184982;
    color: #FFF;
    font-family: 'roboto-bold';
    line-height: .8;
    tr {
      border: none !important;
      th {
        font-size: 12px;
      }
    }
  }
  tbody {
    line-height: .8;
    tr {
      background: #F2F2F2;
      border: none !important;
      td {
        &:first-child {
          width: 130px !important;
          max-width: 130px !important;
          font-family: 'roboto-regular';
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:nth-child(2) {
          width: 80px !important;
          max-width: 80px !important;
          text-align: end;
          font-family: 'roboto-regular';
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
