var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.ifFullDetail)?_c('div',{staticClass:"detail-image-big"},[_c('div',{staticClass:"action"},[_c('div',[_c('img',{staticClass:"action-view-image",attrs:{"src":require("@/assets/images/icons/leftarrow.png"),"alt":""},on:{"click":function($event){$event.preventDefault();return _vm.back.apply(null, arguments)}}})]),_c('div')]),_c('div',{staticClass:"detail"},[_c('img',{staticClass:"image-detail",attrs:{"src":_vm.detailImage,"alt":""}})])]):_vm._e(),(_vm.style.launcher_open_type == 'image' &&
      _vm.style.launcher_close_type == 'color'
      )?_c('div',[_c('img',{staticClass:"sc-launcher open-image close-color custom-open-icon",style:({
        display: _vm.isWindowOpen ? 'none' : 'block'
      }),attrs:{"src":_vm.style.launcher_open},on:{"click":function($event){$event.preventDefault();_vm.isWindowOpen ? _vm.closeChat() : _vm.openChat()}}}),_c('img',{staticClass:"sc-closed-icon open-image close-color custom-open-icon",class:{
        opened: _vm.isWindowOpen
      },style:({
  width: '60px',
  height: '60px',
  'border-radius': '34px 34px 8px 34px',
  background: _vm.style.launcher_close,
  display: _vm.isWindowOpen ? 'block' : 'none'
}),attrs:{"src":require("../assets/images/icons/close-icon.png")},on:{"click":function($event){$event.preventDefault();_vm.isWindowOpen ? _vm.closeChat() : _vm.openChat()}}})]):_vm._e(),(_vm.style.launcher_open_type == 'color' &&
      _vm.style.launcher_close_type == 'image'
      )?_c('div',{staticClass:"sc-launcher",class:{
    opened: _vm.isWindowOpen,
    'open-color': _vm.style.launcher_open_type == 'color' ? true : false,
    'close-image': _vm.style.launcher_close_type == 'image' ? true : false,
    'custom-close-icon':
      _vm.style.launcher_close_type == 'image' ||
        _vm.style.launcher_open_type == 'color'
        ? true
        : false
  },style:({
  background: _vm.isWindowOpen ? 'unset' : _vm.style.launcher_open
}),on:{"click":function($event){$event.preventDefault();_vm.isWindowOpen ? _vm.closeChat() : _vm.openChat()}}},[_c('div'),(_vm.style.launcher_close_type == 'image')?_c('img',{staticClass:"sc-closed-icon",class:{
        image: _vm.style.launcher_close_type == 'image' ? true : false
      },attrs:{"src":_vm.style.launcher_close}}):_vm._e()]):_vm._e(),(_vm.style.launcher_open_type == 'image' &&
      _vm.style.launcher_close_type == 'image'
      )?_c('div',[_c('img',{staticClass:"sc-launcher open-image close-image",style:({
        display: _vm.isWindowOpen ? 'none' : 'block'
      }),attrs:{"src":_vm.style.launcher_open},on:{"click":function($event){$event.preventDefault();_vm.isWindowOpen ? _vm.closeChat() : _vm.openChat()}}}),(_vm.style.launcher_close_type == 'image')?_c('img',{staticClass:"sc-closed-icon open-image close-image",class:{
  image: _vm.style.launcher_close_type == 'image' ? true : false
},style:({
        display: _vm.isWindowOpen ? 'block' : 'none'
      }),attrs:{"src":_vm.style.launcher_close},on:{"click":function($event){$event.preventDefault();_vm.isWindowOpen ? _vm.closeChat() : _vm.openChat()}}}):_vm._e()]):_vm._e(),(_vm.style.launcher_close_type == 'color' &&
      _vm.style.launcher_open_type == 'color'
      )?_c('div',{staticClass:"sc-launcher duo-color",class:{ opened: _vm.isWindowOpen },style:({
        background: _vm.isWindowOpen ? _vm.style.launcher_open : _vm.style.launcher_close
      }),attrs:{"id":"ln-bubble-trigger"},on:{"click":function($event){$event.preventDefault();_vm.isWindowOpen ? _vm.closeChat() : _vm.openChat()}}},[_c('div'),_c('img',{staticClass:"sc-closed-icon",attrs:{"src":require("../assets/images/icons/close-icon.png")}}),_c('img',{staticClass:"sc-open-icon",attrs:{"src":require("../assets/images/icons/chat.png")}})]):_vm._e(),_c('window-main')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }