<template>
  <div class="lenna-message lenna-d-flex lenna-others">
    <message-avatar :isSelf="isSelf" :message="message" />
    <div class="lenna-message-container">
      <message-head :isSelf="isSelf" :message="message" />
      <div class="lenna-message-content lenna-d-flex">
        <div
          class="lenna-text-content"
          :style="{
            'background-color': isSelf ? style.bubble_self : style.bubble_other,
            color: isSelf ? style.text_self : style.text_other
          }"
        >
          <div v-html="renderMessage(content.template)"></div>
        </div>
      </div>
      <message-footer :isSelf="isSelf" :message="message" />
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  },
  methods: {
    renderMessage(message) {
      // eslint-disable-next-line no-useless-escape
      let pattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      let replacedText = message.replace(
        pattern,
        '<a href="$1" target="_blank">$1</a>'
      );
      return replacedText;
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
  .lenna-self {
    //   max-width: 80%;
    flex-direction: row-reverse;
  }
}
.lenna-text-content {
  border-radius: 10px 10px 10px 0;
  padding: 7px 10px;
  line-break: anywhere;
}
</style>
