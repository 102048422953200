<template>
  <div
    class="lenna-message-footer"
    :class="{ 'lenna-self': isSelf }"
    :style="{ color: isSelf ? style.text_self : style.text_other }"
  >
    <div>
      <i
        style="color: #BEBBBB"
        v-show="message.created_at == null"
        class="fas fa-circle-notch fa-spin"
      ></i>
    </div>
    <!-- <small v-show="message.created_at == null">{{now}}</small> -->
    <!-- <small
      :class="{ 'lenna-text-muted': !isSelf }"
      v-show="message.created_at != null"
    > -->
    <small
      v-show="message.created_at != null"
      :style="{ color: isSelf ? style.text_self : style.text_other }"
    >
      <!-- <i
        class="fa fa-check"
        v-show="isSelf"
        :style="{ color: isSelf ? style.text_self : style.text_other }"
      ></i> -->
      <img src="@/assets/images/icons/delivered.png" v-show="isSelf" alt="">
      {{ formatDate(message.created_at, "LT") }}
    </small>
  </div>
</template>
<script>
import formatter from "@/helpers/formatter";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: ["isSelf", "message"],
  computed: {
    now() {
      return moment().format("LT");
    },
    ...mapGetters({
      style: "style/getStyle",
      isTyping: "chat/isTyping"
    })
  },
  methods: {
    formatDate(date, format) {
      return formatter.formatDate(date, format);
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message-footer {
  font-size: 0.8rem;
  // color: $dark;
}
.lenna-message-footer.lenna-self {
  width: 100%;
  small {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    color: #5E5E5E !important;
    font-family: 'roboto-regular' !important;
    i {
      margin-right: 5px;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }
}
</style>
