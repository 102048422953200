<template>
  <div
    class="lenna-message lenna-d-flex lenna-others"
    :class="{
      'lenna-others-block': !isSelf
    }"
  >
    <div
      :class="{
        'lenna-d-flex lenna-custom-header': !isSelf
      }"
    >
      <message-avatar :isSelf="isSelf" :message="message" />
      <message-head :isSelf="isSelf" :message="message" />
    </div>
    <div class="lenna-message-container">
      <div
        class="lenna-message-content lenna-d-flex"
        :style="{
          'background-color': isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other,
          '--color': isSelf ? style.bubble_self : style.bubble_other
        }"
      >
        <div class="lenna-text-content">
          <p v-html="content.content"></p>
        </div>
      </div>
      <message-footer :isSelf="isSelf" :message="message" class="lenna-custom-html-footer"/>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 7px 10px;
  .lenna-text-content {
    // padding: 7px 10px;
    font-family: "Aller_Rg", sans-serif !important;
    // border-radius: 10px 10px 10px 0;
    border-radius: 20px;
    // padding: 10px 22px 8px 16px;

    img {
      max-width: 250px !important;
    }
  }

  img {
    max-width: 250px !important;
  }
}

.lenna-message-image {
  width: 150px;
}

.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;
  .lenna-message-content {
    text-align: right;
    // color: white;
    // background: $dark-blue;
    border-radius: 10px 10px 0 10px;
  }
}

</style>
