<template>
  <div
    class="lenna-message lenna-d-flex lenna-flex-wrap lenna-self lenna-custom-flex-wrap"
    v-show="quickButtonList"
  >
    <!-- :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }" -->
    <div
      v-for="(quickbtn, key) in quickButtonList"
      :key="key"
      class="lenna-m-1"
    >
      <div
        v-if="marquee(quickbtn)"
        :class="{
          'marquee-full-width': checkMarquee(key),
          'marquee-fit-width': !checkMarquee(key)
        }"
        :style="{
          'overflow-x': setOverflow(quickbtn)
        }"
        class="lenna-pointer lenna-px-2 lenna-py-2 lenna-quickbutton-wrapper lenna-custom-rounded-border lenna-box-shadow custom-scrollbar"
        @click="quickButtonClick(quickbtn)"
        @mouseover="showMarquee(true, key)"
        @mouseleave="showMarquee(false, key)"
      >
        <div class="lenna-quickbutton-item">
          <marquee-text
            v-if="checkMarquee(key)"
            :paused="paused(quickbtn)"
            :repeat="1"
            :duration="6"
            :key="key"
          >
            {{ quickbtn }}
          </marquee-text>
          <div v-if="!checkMarquee(key)" :key="key">
            {{ truncateText(quickbtn) }}
          </div>
        </div>
      </div>
      <div
        v-else
        :style="{
          width: 'fit-content',
          'overflow-x': 'hidden'
        }"
        class="lenna-pointer lenna-px-2 lenna-py-2 lenna-quickbutton-wrapper lenna-custom-rounded-border lenna-box-shadow"
        @click="quickButtonClick(quickbtn)"
      >
        <div class="lenna-quickbutton-item">
          <div :key="key">
            {{ quickbtn }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import MarqueeText from "vue-marquee-text-component";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  components: {
    MarqueeText
  },
  data() {
    return {
      time: 0,
      length: 0,
      currentKey: 0
    };
  },
  computed: {
    ...mapGetters({
      style: "style/getStyle",
      quickButtonList: "chat/quickButtonList"
    })
  },
  created() {
    this.showMarquee(false, 0);
  },
  methods: {
    checkMarquee(key) {
      if (key == this.currentKey) {
        return true;
      } else {
        return false;
      }
    },
    showMarquee(data, key) {
      if (data) {
        this.currentKey = key;
      } else {
        this.currentKey = -1;
      }
      return data;
    },
    truncateText(text) {
      const data = _.truncate(text, {
        length: 40
      });
      return data;
    },
    marquee(text) {
      if (_.size(text) < 55) {
        return false;
      } else {
        return true;
      }
    },
    paused(text) {
      if (_.size(text) < 55) {
        return true;
      } else {
        return false;
      }
    },
    setOverflow(text) {
      if (_.size(text) < 55) {
        return "hidden";
      } else {
        return "scroll";
      }
    },
    setWidth(showMarquee = true) {
      if (showMarquee) {
        return "fit-content";
      } else {
        return "100%";
      }
    },
    overflowText(text) {
      if (_.size(text) > 55) {
        return "scroll";
      } else {
        return "hidden";
      }
    },
    async quickButtonClick(value) {
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      if (value == "Batalkan live agent") {
        let textMessage = {
          type: "text",
          text: "Batal live agent"
        };
        await this.$store.dispatch("chat/cancelLiveAgent");
        await this.$store.dispatch("chat/sendMessage", textMessage);
      } else {
        let textMessage = {
          type: "text",
          text: value
        };
        this.$store.dispatch("chat/updateQuickButton", []);
        await this.$store.dispatch("chat/sendMessage", textMessage);
      }
      this.$store.dispatch("other/triggerScroll", false);
    }
  }
};
</script>
<style lang="scss" scoped>
.marquee-full-width {
  width: 170px;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.marquee-fit-width {
  width: 170px;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lenna-message {
  padding: 5px 10px;
  margin-left: 10px;
  transition: height 1s, opacity 1s;
}
.lenna-quickbutton-wrapper {
  // ::-webkit-scrollbar {
  //   display: none;
  // }
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  background: white;
  color: #1a8fdd;
  font-family: "roboto-regular";
  font-size: 12px;
  border: 1px solid #1a8fdd;
  border-radius: 20px;
  padding: 6px 10px !important;
  box-shadow: none;
  &:hover {
    background: $gray;
    color: white;
  }
  .lenna-quickbutton-item {
    span {
      width: 100%;
      // position: relative;
      // left: 0px;
      // transform: translateX(0%);
      // transition: left 0.25s ease;
    }
    position: relative;
    white-space: nowrap;
    // overflow: hidden;

    // padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    // box-sizing: content-box;
  }
}
.lenna-message.lenna-self {
  flex-direction: row-reverse;
}
.lenna-custom-flex-wrap {
  flex-direction: row !important;
}
.custom-scrollbar::-webkit-scrollbar {
  display: none !important;
}
// .custom-scrollbar::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 7px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgba(0, 0, 0, 0.5);
//   box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }
</style>
