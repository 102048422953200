<template>
  <div class="lenna-message-avatar" :class="{ 'lenna-self': isSelf }">
    <div v-if="message">
      <img
        :src="message.messageable.picture"
        class="lenna-rounded-circle lenna-align-items-start"
        @error="imgErrorHandler"
        v-if="
          message.messageable.picture &&
            message.messageable_type == 'user_platform' &&
            message.messageable.picture !== null
        "
      />
      <img
        :src="style.avatar"
        class="lenna-rounded-circle lenna-align-items-start else"
        @error="imgErrorHandler"
        v-else
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isUndefined } from "lodash";
export default {
  props: ["isSelf", "message"],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  },
  // data() {
  //   return {
  //     profile: this.style.avatar
  //   };
  // },
  methods: {
    imgErrorHandler(event) {
      event.target.src = require(`@/assets/images/logo_lenna_grey.png`);
    },
  },
  mounted() {
    // console.log('message head is self', this.isSelf);
    // console.log('message head first message', this.message);
    if (!isUndefined(this.isSelf) && !isUndefined(this.message)) {
      // console.log('message head messageable type', this.message.messageable_type);
      // switch (this.message.messageable_type) {
      //   case "user_platform":
      //     this.profile = this.message.messageable
      //       ? this.message.messageable.picture != null
      //         ? this.message.messageable.picture
      //         : this.style.avatar
      //       : this.style.avatar;
      //     break;
      //   default:
      //     this.profile = this.style.avatar;
      //     break;
      // }
      // if (this.message.messageable_type == "user_platform") {
      // }
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message-avatar {
  margin-right: 0.6rem;
  img {
    width: 35px;
  }
}
.lenna-message-avatar.lenna-self {
  display: none;
}
</style>
