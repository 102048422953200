import Vue from "vue";
import VeeValidate from "vee-validate";
import InfiniteLoading from "vue-infinite-loading";
import VueScrollTo from "vue-scrollto";
// import reactiveStorage from "vue-reactive-storage";
import VueLazyload from "vue-lazyload";
import Viewer from "v-viewer";
import Notifications from "vue-notification";
import VueMeta from 'vue-meta'

Vue.use(VeeValidate, { events: "change" });
Vue.use(InfiniteLoading, {
  props: {
    spinner: "spiral",
    distance: 50
  },
  system: {
    throttleLimit: 1
  }
});
Vue.use(VueScrollTo);
// Vue.use(reactiveStorage);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("@/assets/images/image_not_found.jpg"),
  loading: require("@/assets/images/ripple.gif"),
  attempt: 1
});
Vue.use(Viewer, {
  defaultOptions: {
    movable: false,
    zIndex: 9999,
    filter(image) {
      return image.classList.contains("zoomable");
      // return image.getAttribute("class").includes("zoomable");
    }
  }
});
Vue.use(Notifications);
Vue.use(VueMeta);
// global mixin
import coreMixin from "@/mixins/coreMixin";
Vue.mixin(coreMixin);
