<template>
  <div
    class="lenna-message-head"
    style="align-self: center;"
    :class="{ 'lenna-self': isSelf }"
  >
    <p class="" style="margin-bottom: 0px;">{{ name }}</p>
    <p
      class="lenna-text-muted lenna-roboto-regular-font"
      style="margin-bottom: 0px;margin-top: -3px!important;"
    >
      {{ description }}
    </p>
  </div>
</template>
<script>
import isUndefined from "lodash/isUndefined";
export default {
  data() {
    return {
      name: "",
      description: ""
    };
  },
  props: {
    isSelf: {
      type: Boolean
    },
    message: {
      type: Object
    }
  },
  mounted() {
    // console.log('message head is self', this.isSelf);
    // console.log('message head first message', this.message);
    if (!isUndefined(this.isSelf) && !isUndefined(this.message)) {
      // console.log('message head messageable type', this.message.messageable_type);
      if (
        this.message.messageable_type == "user_platform" ||
        this.message.messageable_type == "bot"
      ) {
        switch (this.message.messageable_type) {
          case "bot":
            // console.log("message head messageable global", this.message.messageable);
            this.name = this.message.messageable.name;
            this.description = this.message.messageable.description
              ? this.message.messageable.description
              : "";
            break;
          case "user_platform":
            this.name = this.message.messageable
              ? this.message.messageable.name
              : "Live Agent";
            this.description = "Live Agent";
            break;
          default:
            this.name = "";
            this.description = "";
            break;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message-head.lenna-self {
  display: none;
}

.lenna-message-head {
  p {
    font-family: "roboto-medium";
    text-transform: capitalize;
    font-size: 12px;
    margin: 0 !important;
    font-weight: 800;
  }
}
</style>
