import truncate from "lodash/truncate";
export default {
  data() {
    return {
      baseUrl: "https://app.lenna.ai/webchat/"
    };
  },
  methods: {
    logx(data) {
      console.log(data);
    },
    imgErrorHandler(event) {
      event.target.src = require(`@/assets/images/logo_lenna_grey.png`);
    },
    asset(path) {
      return `${this.baseUrl}/${path}`;
    },
    parseJson(content) {
      return JSON.parse(content);
    },
    acronymText(text) {
      try {
        if (text) {
          var matches = text.match(/\b(\w)/g); // ['J','S','O','N']
          var acronym = matches.join("").toUpperCase();
          return truncate(acronym, {
            length: 3
          });
        } else {
          return "#";
        }
      } catch (error) {
        return truncate(text, {
          length: 2
        });
      }
    }
  }
};
