<template>
  <div>
    <!-- Launcher Open Image || Close Color -->
    <div class="detail-image-big" v-if="ifFullDetail">
      <div class="action">
        <div>
          <img src="@/assets/images/icons/leftarrow.png" class="action-view-image" alt="" @click.prevent="back" />
        </div>
        <div>
          <!-- <img src="@/assets/images/icons/download.png" class="action-view-image" @click.prevent="downloadImage(`${detailImage}`)" alt=""> -->
        </div>
      </div>
      <div class="detail">
        <img :src="detailImage" class="image-detail" alt="" />
      </div>
    </div>
    <div v-if="style.launcher_open_type == 'image' &&
      style.launcher_close_type == 'color'
      ">
      <img class="sc-launcher open-image close-color custom-open-icon" :style="{
        display: isWindowOpen ? 'none' : 'block'
      }" :src="style.launcher_open" @click.prevent="isWindowOpen ? closeChat() : openChat()" />
      <img class="sc-closed-icon open-image close-color custom-open-icon" :class="{
        opened: isWindowOpen
      }" :style="{
  width: '60px',
  height: '60px',
  'border-radius': '34px 34px 8px 34px',
  background: style.launcher_close,
  display: isWindowOpen ? 'block' : 'none'
}" @click.prevent="isWindowOpen ? closeChat() : openChat()" src="../assets/images/icons/close-icon.png" />
    </div>

    <!-- Launcher Open Color || Close Image -->
    <div v-if="style.launcher_open_type == 'color' &&
      style.launcher_close_type == 'image'
      " class="sc-launcher" :class="{
    opened: isWindowOpen,
    'open-color': style.launcher_open_type == 'color' ? true : false,
    'close-image': style.launcher_close_type == 'image' ? true : false,
    'custom-close-icon':
      style.launcher_close_type == 'image' ||
        style.launcher_open_type == 'color'
        ? true
        : false
  }" @click.prevent="isWindowOpen ? closeChat() : openChat()" :style="{
  background: isWindowOpen ? 'unset' : style.launcher_open
}">
      <div></div>
      <img v-if="style.launcher_close_type == 'image'" class="sc-closed-icon" :class="{
        image: style.launcher_close_type == 'image' ? true : false
      }" :src="style.launcher_close" />
      <!-- <img class="sc-open-icon" src="../assets/images/icons/chat.png" /> -->
    </div>

    <!-- Launcher Open Image || Close Image -->
    <div v-if="style.launcher_open_type == 'image' &&
      style.launcher_close_type == 'image'
      ">
      <img class="sc-launcher open-image close-image" :style="{
        display: isWindowOpen ? 'none' : 'block'
      }" :src="style.launcher_open" @click.prevent="isWindowOpen ? closeChat() : openChat()" />
      <img v-if="style.launcher_close_type == 'image'" class="sc-closed-icon open-image close-image" :style="{
        display: isWindowOpen ? 'block' : 'none'
      }" :class="{
  image: style.launcher_close_type == 'image' ? true : false
}" :src="style.launcher_close" @click.prevent="isWindowOpen ? closeChat() : openChat()" />
    </div>

    <!-- Launcher Open Color || Close Color -->
    <div v-if="style.launcher_close_type == 'color' &&
      style.launcher_open_type == 'color'
      " class="sc-launcher duo-color" id="ln-bubble-trigger" :class="{ opened: isWindowOpen }"
      @click.prevent="isWindowOpen ? closeChat() : openChat()" :style="{
        background: isWindowOpen ? style.launcher_open : style.launcher_close
      }">
      <div></div>
      <img class="sc-closed-icon" src="../assets/images/icons/close-icon.png" />
      <img class="sc-open-icon" src="../assets/images/icons/chat.png" />
    </div>
    <window-main></window-main>
  </div>
</template>

<script>
import WindowMain from "./window/WindowMain";
import { mapState, mapGetters } from "vuex";

export default {
  components: { WindowMain },
  data() {
    return {
      firstOpen: true
    };
  },
  computed: {
    ...mapState("window", ["isOpen"]),
    ...mapGetters({
      isWindowOpen: "window/isOpen",
      style: "style/getStyle",
      detailImage: "chat/detailImage",
      ifFullDetail: "chat/fullDetail",
      useRegisterForm: "style/useRegisterForm"
    })
  },
  methods: {
    async openChat() {
      await this.$store.dispatch("window/updateIsOpen", true);
      let isLogedIn = await this.$store.getters["user/isLogedIn"];
      if (this.firstOpen) {
        if (this.useRegisterForm != undefined && !this.useRegisterForm) {
          this.$store.commit("other/SET_REGISTER_LOADING", true);
          if (this.$store.getters.userToken) {
            await this.$store.dispatch(
              "user/autoRegister",
              this.$store.getters.userToken,
              {
                root: true
              }
            );
          } else {
            this.$store.dispatch("user/autoRegister", {}, { root: true });
          }
          this.$store.commit("other/SET_REGISTER_LOADING", true);
        }
        // await this.$store.dispatch("user/checkRegister");
        await this.$store.dispatch("other/checkEnabledLocation").then(() => {
          if (this.isEnabledLocation) {
            this.$store.dispatch("user/updateLocation");
          }
        });
        if (isLogedIn) {
          console.log(this.$store.getters["user/user"]);
          let id = await this.$store.getters["user/user"].id;
          let page = await this.$store.getters["chat/page"];
          await this.$store.dispatch("chat/getHistory", {
            id: id,
            page: page
          });
          this.firstOpen = false;
          this.$store.dispatch("chat/updatePage", 1);
          //   let textMessage = {
          //     type: "text",
          //     text: "hai"
          //   };
          //   this.$store.dispatch("chat/sendMessage", textMessage);
        }
      }
    },
    closeChat() {
      this.$store.dispatch("window/updateIsOpen", false);
    },
    back() {
      this.$store.dispatch("chat/toggleView", {
        file: "",
        status: false,
        isFull: false
      });
    },
    downloadImage(url) {
      axios
        .get(url, { responseType: "blob" })
        .then(res => {
          const blob = new Blob([res.data], { type: "application/png" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(err => console.log("error download image", err));
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.png"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$store.dispatch("chat/checkLivechat");
      }
    }
  },
  created() {
    // if (localStorage.getItem("webchat_style")) {
    //   this.style = JSON.parse(localStorage.getItem("webchat_style"));
    //   console.log("style", this.style);
    // }
    // this.style = this.$store.getters["style/getStyle"];
    // this.$store.dispatch("style/setStyle");
  }
};
</script>

<style lang="scss" scoped>
// Open color || close coloe
.sc-launcher.duo-color {
  z-index: 9999;
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-radius: 34px 34px 8px 34px;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 34px 34px 8px 34px;
    transition: box-shadow 0.2s ease-in-out;
  }

  &:hover {
    box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
  }

  .sc-closed-icon,
  .sc-open-icon {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 25px;
    bottom: 25px;
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  }

  .sc-open-icon {
    width: 30px;
    right: 39px;
    bottom: 40px;
    height: 28px;
  }

  .sc-closed-icon {
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
  }

  &.opened {
    .sc-closed-icon {
      transform: rotate(-90deg);
      opacity: 1;
    }

    .sc-open-icon {
      transform: rotate(-90deg);
      opacity: 0;
    }

    &:before {
      box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
    }
  }
}

// .sc-launcher {
//   bottom: 10px;
//   right: 30px;
//   z-index: 9999;
//   position: fixed;
// }
// Open Color || Close Image
.custom-close-icon.open-color.close-image {
  &.sc-launcher {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    right: 25px;
    bottom: 25px;
    position: absolute;
    z-index: 9999;
    border-radius: 34px 34px 8px 34px;
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;

    &:before {
      // content: "";
      position: relative;
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 34px 34px 8px 34px;
      transition: box-shadow 0.2s ease-in-out;
    }

    // &:hover {
    //   box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
    // }
    &.opened {
      background: unset;

      .sc-closed-icon {
        opacity: 1;
        width: auto;
        height: auto;
        bottom: 0px;
        right: 9px;
        display: block;

        &.opened {
          background: unset;
        }
      }

      .sc-open-icon {
        transform: rotate(-90deg);
        opacity: 0;
      }

      &:before {
        box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
      }
    }

    .sc-closed-icon,
    .sc-open-icon {
      position: fixed;
      bottom: 0px;
      transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
    }

    .sc-open-icon {
      width: 30px;
      right: 39px;
      bottom: 40px;
      height: 28px;
    }

    .sc-closed-icon {
      padding: 20px;
      width: 60px;
      display: none;
      height: 60px;
      right: 25px;
      bottom: 25px;
      box-sizing: border-box;
      // opacity: 0;
    }
  }
}

// Open Image || Close Color
.sc-closed-icon.custom-open-icon.open-image.close-color {
  position: fixed;

  &.opened {
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
    background: unset;
  }

  padding: 20px;
  box-sizing: border-box;
  right: 25px;
  bottom: 25px;
  opacity: 1;
  transform: rotate(-90deg);
  cursor: pointer;
}

.sc-launcher.custom-open-icon.open-image.close-color {
  width: auto;
  max-height: 130px;
  background-position: right !important;
  background-size: 130px 130px;
  background-repeat: no-repeat !important;
  right: 25px;
  bottom: 5px;
  position: fixed;
  z-index: 9999;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  .sc-open-icon {
    transform: rotate(-90deg);
    opacity: 0;
  }

  &:before {
    box-shadow: unset;
  }

  &:before {
    content: "";
    position: relative;
    display: block;
    width: auto;
    height: auto;
    // border-radius: 34px 34px 8px 34px;
    transition: box-shadow 0.2s ease-in-out;
  }

  &:hover {
    box-shadow: unset;
  }

  .sc-closed-icon {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    position: fixed;
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  }

  .sc-open-icon {
    bottom: 40px;
    height: 28px;
  }

  // .sc-closed-icon {
  //   display: none;
  //   padding: 20px;
  //   box-sizing: border-box;
  //   right: 25px;
  //   bottom: 25px;
  //   opacity: 0;
  // }
}

// Open Image || Close Image
.sc-launcher.open-image.close-image {
  width: auto;
  max-height: 130px;
  background-position: right !important;
  background-size: 130px 130px;
  background-repeat: no-repeat !important;
  right: 30px;
  bottom: 5px;
  position: fixed;
  z-index: 9999;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  .sc-open-icon {
    transform: rotate(-90deg);
    opacity: 0;
  }

  &:before {
    box-shadow: unset;
  }

  &:before {
    content: "";
    position: relative;
    display: block;
    width: auto;
    height: auto;
    // border-radius: 34px 34px 8px 34px;
    transition: box-shadow 0.2s ease-in-out;
  }

  &:hover {
    box-shadow: unset;
  }

  .sc-closed-icon {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    position: fixed;
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  }

  .sc-open-icon {
    bottom: 40px;
    height: 28px;
  }

  // .sc-closed-icon {
  //   display: none;
  //   padding: 20px;
  //   box-sizing: border-box;
  //   right: 25px;
  //   bottom: 25px;
  //   opacity: 0;
  // }
}

.sc-closed-icon.open-image.close-image {
  position: absolute;

  &.opened {
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
    background: unset;
  }

  max-height: 100px;
  box-sizing: border-box;
  right: 30px;
  bottom: 5px;
  opacity: 1;
  cursor: pointer;
}

// detail image
.detail-image-big {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0;
  left: 0;
  z-index: 10000 !important;
}

.image-detail {
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  position: relative;
}

.detail {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.action {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  z-index: 11000 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.action-view-image {
  width: 24px;
  height: 24px;
}

// end detail image
</style>
