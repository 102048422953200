<template>
  <div
    class="lenna-message lenna-d-flex lenna-others"
    :class="{ 'lenna-others-block': !isSelf }"
    style="padding-left: 0 !important; padding-right: 0 !important;"
  >
    <!-- <message-avatar :isSelf="isSelf" :message="message" /> -->
    <div
      :class="{
        'lenna-d-flex lenna-custom-header lenna-custom-carousel': !isSelf
      }"
    >
      <message-avatar :isSelf="isSelf" :message="message" />
      <message-head :isSelf="isSelf" :message="message" />
    </div>
    <div class="lenna-message-container">
      <!-- <message-head :isSelf="isSelf" :message="message" /> -->

      <!-- <div
        class="lenna-message-content lenna-d-flex custom-carousel-content"
        :class="{
          'custom-scrollbar-visible': appId == 557 || appId == 616,
          'custom-scrollbar': appId != 557 && appId != 616
        }"
      > -->
      <div
        class="lenna-message-content lenna-d-flex custom-carousel-content custom-scrollbar-visible"
      >
        <div
          :style="{
            'background-color': style.bubble_other
          }"
          class="lenna-card lenna-carousel-container"
          v-for="(carousel, key) in content.columns"
          :key="key"
        >
          <div class="lenna-container-carousel-img">
            <img
              class="lenna-card-img-top lenna-mx-auto lenna-carousel-img lenna-img-fluid"
              :src="
                carousel.thumbnailImageUrl !== `${botStudioUrl}/storage/`
                  ? carousel.thumbnailImageUrl
                    ? carousel.thumbnailImageUrl
                    : require('@/assets/images/image_not_found.jpg')
                  : require('@/assets/images/image_not_found.jpg')
              "
            />
          </div>
          <div class="lenna-card-body lenna-carousel-card-body">
            <p
              class="lenna-card-title"
              :style="{
                color: style.text_other
              }"
            >
              {{ carousel.title }}
            </p>
            <!-- <p class="lenna-card-text lenna-text-muted"> -->
            <p
              class="lenna-card-text lenna-roboto-regular-font"
              :style="{
                color: '#969e9e'
              }"
            >
              {{ carousel.text }}
            </p>
          </div>
          <ul
            class="lenna-list-group lenna-list-group-flush lenna-text-center lenna-text-color"
          >
            <li
              :style="{
                'background-color': style.bubble_other
              }"
              class="lenna-list-group-item lenna-carousel-list-group-item"
              style="cursor:pointer"
              v-for="(item, index) in carousel.actions"
              :key="index"
              @click="actionsClickHandler(item)"
            >
              <p class="lenna-carousel-action-text">{{ item.label }}</p>
            </li>
          </ul>
        </div>
      </div>
      <message-footer :isSelf="isSelf" :message="message" />
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    }),
    appId() {
      return this.$store.getters.decodedAppId[0];
    }
  },
  data() {
    return {
      botStudioUrl: process.env.VUE_APP_BOTSTUDIO_URL
    };
  },
  methods: {
    actionsClickHandler(item) {
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      console.log("item action carousel", item);
      switch (item.type) {
        case "postback":
          this.sendText(item.data == "" ? item.label : item.data);
          break;
        case "uri":
          this.openURI(item.uri);
          break;
        default:
          break;
      }
      this.$store.dispatch("other/triggerScroll", false);
    },
    sendText(value) {
      let textMessage = {
        type: "text",
        text: value
      };
      this.$store.dispatch("chat/sendMessage", textMessage);
    },
    openURI(value) {
      window.open(value, "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;
}
.lenna-custom-carousel {
  padding-left: 18px !important;
}
.lenna-message-content {
  overflow-x: scroll;
  // max-width: 200px;
  padding: 5px;
}
.lenna-carousel-container {
  min-width: 200px;
  margin-right: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border: none;
}
.lenna-carousel-list-group-item {
  border-radius: 0 0 8px 8px !important;
  border: none;
  border-top: 1px solid #e0e0e0;
}
.lenna-card-title {
  font-family: "roboto-bold" !important;
  font-size: 14px;
}
ul {
  li:last-child.lenna-rounded-border-bottom {
    border-radius: 0px 0px 10px 10px !important;
  }
}
.lenna-carousel-img {
  // height: 180px;
  // width: 170px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.lenna-container-carousel-img {
  padding: 6px;
}
.lenna-carousel-action-text {
  font-family: "roboto-regular";
  font-weight: bold;
  font-family: 14px;
  color: #1a8fdd;
}
p {
  line-height: 15px;
  margin-bottom: 0.1rem !important;
}
.lenna-list-group-item {
  padding: 0.5rem 0.25rem;
  span {
    font-family: "Aller_Rg", sans-serif;
  }
}
.lenna-card-body {
  padding: 0.5rem;
}
.custom-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.custom-scrollbar-visible::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
  height: 5px;
  padding-left: 28px;
  padding-right: 18px;
}

.custom-scrollbar-visible::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0.5px rgba(255, 255, 255, 0.5);
}
.lenna-others .custom-carousel-content {
  max-width: 90%;
  left: 0px !important;
  padding-left: 28px;
  padding-right: 18px;
  transition: all 0.5s;
}
.lenna-others .custom-carousel-content:hover {
  // max-width: 100% !important;
}
$mobile-s: 320px;
$mobile-m: 375px;
$mobile-l: 425px;
$tablet: 768px;
$desktop: 1024px;
// @media (max-width: 319px) {
//   .lenna-message-content {
//     max-width: 155px;
//   }
// }
// @media (min-width: $mobile-s) {
//   .lenna-message-content {
//     max-width: 195px;
//   }
// }
// @media (min-width: $mobile-m) {
//   .lenna-message-content {
//     max-width: 250px;
//   }
// }
// @media (min-width: $mobile-l) {
//   .lenna-message-content {
//     max-width: 300px;
//   }
// }
// @media (min-width: $tablet) {
//   .lenna-message-content {
//     max-width: 645px;
//   }
// }
// @media (min-width: $desktop) {
//   .lenna-message-content {
//     max-width: 335px;
//   }
// }
</style>
