<template>
  <transition name="fade" enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
    <div :style="{ bottom: bottom }" class="lenna-main-window lenna-rounded-border lenna-card lenna-mb-3"
      v-show="isWindowOpen" style="animation-duration: 0.4s" id="main-window">
      <div id="ln-caption-bubble"></div>
      <view-image v-if="isView" />
      <window-header />
      <chat-body v-if="isLogedIn && !isFeedBack" />
      <register v-if="!isLogedIn" />
      <feedback v-if="!user_webchat == null && isFeedBack" />
      <lenna-credit v-if="!isLogedIn || isFeedBack" />
      <chat-footer v-if="isLogedIn && !isFeedBack" />
      <rating v-if="isLogedIn && !isFeedBack" />
      <credit-bottom-chat v-if="isLogedIn && !isFeedBack" />
    </div>
  </transition>
</template>
<script>
import WindowHeader from "@/components/window/WindowHeader";
import ChatBody from "@/components/chat/ChatBody";
import ViewImage from "@/components/chat/ViewImage";
import ChatFooter from "@/components/chat/ChatFooter";
import Rating from "@/components/chat/Rating";
import Register from "@/components/form/Register";
import LennaCredit from "@/components/window/LennaCredit";
import CreditBottomChat from "../window/CreditBottomChat";
import Feedback from "@/components/form/Feedback";
import { mapGetters } from "vuex";
export default {
  components: {
    WindowHeader,
    ChatBody,
    ChatFooter,
    Register,
    LennaCredit,
    CreditBottomChat,
    Feedback,
    ViewImage,
    Rating
  },
  data() {
    return {
      bottom: "85px",
      location: {
        latlong: null,
        gettingLocation: false,
        errorStr: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      style: "style/getStyle",
      isLogedIn: "user/isLogedIn",
      isWindowOpen: "window/isOpen",
      isFeedBack: "user/logout",
      isEnabledLocation: "other/isEnabledLocation",
      isView: "chat/isViewImage"
    }),
    user_webchat() {
      if (!localStorage) {
        return null;
      } else {
        return localStorage.webchat_user
      }
    }
  },
  async created() {
    // if (this.$store.getters.decodedAppId[0] === 1) {
    //   this.bottom = "100px";
    // }
    // await this.$store.dispatch("user/checkRegister");
    // this.$store.dispatch("other/checkEnabledLocation").then(() => {
    //   if (this.isEnabledLocation) {
    //     this.$store.dispatch("user/updateLocation");
    //   }
    // });
  }
};
</script>
<style lang="scss" scoped>
.lenna-main-window {
  z-index: 9999;
  position: fixed !important;
  width: 400px;
  bottom: 85px;
  right: 30px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  border: none;
}

@media only screen and (max-width: 1023px) {
  .lenna-main-window {
    width: auto;
    right: 30px;
    left: 30px;
  }
}
</style>
