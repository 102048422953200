import Vue from "vue";
import {
  remove,
  size,
  isNull,
  forEach,
  assign,
  toArray,
  some,
  add,
  sortBy,
  cloneDeep
} from "lodash";
import moment from "moment";
import LennaMessageType from "@/helpers/messageType";
import encryptor from "@/helpers/encryptor";
import { OMNICHANNEL_URL } from "@/utils/variables";
// import reactiveStorage from "vue-reactive-storage";
import Hashids from "hashids";
const hashids = new Hashids("", 6);
// Vue.use(reactiveStorage, {
//   lenna_queue: null
// });
const state = {
  messageList: [],
  contentImage: [],
  detailImage: "",
  quickButtonList: ["Hai", "Halo, apa kabar?"],
  unreadMessage: null,
  isTyping: false,
  isFirst: false,
  firstIdMessage: "",
  page: 1,
  isRequestLiveAgent: false,
  roomId: 0,
  queueData: null,
  queueParam: {
    max: 0,
    estimate: 0
  },
  maskingInput: false,
  isView: false,
  fullDetail: false,
  isRating: false,
  ratingData: null
};

const getters = {
  isViewImage(state) {
    return state.isView;
  },
  detailImage(state) {
    return state.detailImage;
  },
  messageList(state) {
    return state.messageList;
  },
  contentImage(state) {
    return state.contentImage;
  },
  quickButtonList(state) {
    return state.quickButtonList;
  },
  unreadMessage(state) {
    return state.unreadMessage;
  },
  isTyping(state) {
    return state.isTyping;
  },
  isFirst(state) {
    return state.isFirst;
  },
  firstIdMessage(state) {
    return state.firstIdMessage;
  },
  page(state) {
    return state.page;
  },
  getIsRequestLiveAgent(state) {
    return state.isRequestLiveAgent;
  },
  getRoomId(state) {
    return state.roomId;
  },
  getQueueData(state) {
    if (localStorage.lenna_queue) {
      state.queueData = JSON.parse(localStorage.lenna_queue) || null;
      return state.queueData;
    }
    return state.queueData;
  },
  getEstimateQueue(state) {
    const queueIndex = state.queueData.index;
    /**
     * 30 = 30 seconds
     * */
    if (queueIndex > 0) {
      return state.queueParam.estimate * add(queueIndex, 1) * 60;
    }
    if (queueIndex == 0) {
      return state.queueParam.estimate * 1 * 60;
    }
  },
  maskingInput(state) {
    return state.maskingInput;
  },
  fullDetail(state) {
    return state.fullDetail;
  }
};
const mutations = {
  UPDATE_TYPING: (state, payload) => {
    state.isTyping = payload;
  },
  DELETE_FIRST_MESSAGE: state => {
    // console.log("DELETE_FIRST_MESSAGE");
    state.messageList.shift();
  },
  ADD_MESSAGE: (state, payload) => {
    let messageText = payload.content.find(v => {
      return v.type == "text";
    });
    if (messageText) {
      if (messageText.text.includes("lenna.ai/rating/case")) {
        state.isRating = true;
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        state.ratingData = messageText.text.replace(urlRegex, function(url) {
          return url;
        });
        return;
      }
    }
    // console.log("ADD_MESSAGE", messageText);
    remove(state.messageList, function(o) {
      return o.id == payload.id;
    });
    state.messageList.push(payload);
  },
  ADD_CONTENT_IMAGE: (state, payload) => {
    state.contentImage = [];
    state.contentImage = payload;
  },
  REMOVE_CONTENT_IMAGE: state => {
    state.contentImage = [];
  },
  UPDATE_QUICKBUTTON: (state, payload) => {
    state.quickButtonList = payload;
  },
  UPDATE_MESSAGE: (state, payload) => {
    // console.log("UPDATE_MESSAGE", payload);
    let messageIndex = state.messageList.findIndex(function(obj) {
      return obj.temporary_id == payload.temporaryId;
    });
    state.messageList[messageIndex].created_at = payload.message.created_at;
  },
  HANDLE_NEW_MESSAGE: (state, payload) => {
    let messageIndex = state.messageList.findIndex(function(obj) {
      return obj.temporary_id == payload.temporary_id;
    });
    state.messageList.push(payload);
    state.messageList[messageIndex].created_at = payload.created_at;
  },
  UPDATE_IS_FIRST: (state, payload) => {
    state.isFirst = payload;
  },
  UPDATE_FIRST_ID_MESSAGE: (state, payload) => {
    state.firstIdMessage = payload;
  },
  ADD_MORE_MESSAGES: (state, payload) => {
    if (size(state.messageList) == 0) {
      state.messageList = payload;
    } else {
      // const messages = _.concat(state.messageList, payload);
      // const messages = state.messageList.unshift(...payload);
      // console.log("messageList", messages);
      // state.messageList = _.orderBy(messages, "created_at", "asc");
      // console.log("messages", messages);
    }
    state.roomId = state.messageList[0].room_id || 0;
  },
  ADD_PAGE: state => {
    state.page++;
  },
  UPDATE_REQUEST_LIVEAGENT: (state, payload) => {
    state.isRequestLiveAgent = payload;
    if (payload) {
      state.quickButtonList = ["Batalkan live agent"];
    } else {
      // state.quickButtonList = [];
    }
  },
  UPDATE_ROOM_ID: (state, payload) => {
    state.roomId = payload;
  },
  REMOVE_MESSAGE_TYPE_FORM_AFTER_SUBMIT: state => {
    console.log("REMOVE_MESSAGE_TYPE_FORM_AFTER_SUBMIT");
    state.messageList.splice(state.messageList.length - 1, 1);
  },
  SET_QUEUE: (state, payload) => {
    state.queueData = payload;
  },
  SET_QUEUE_PARAM: (state, payload) => {
    if (payload.type == "max") {
      state.queueParam.max = payload.value;
    } else {
      state.queueParam.estimate = payload.value;
    }
  },
  SET_MASKING: (state, payload) => {
    state.maskingInput = payload;
  },
  CHANGE_VIEW_IMAGE: (state, payload) => {
    state.isView = payload;
    state.fullDetail = false;
  },
  TOGGLE_DETAIL_IMAGE: (state, payload) => {
    state.detailImage = payload;
  },
  FULL_DETAIL_IMAGE: (state, payload) => {
    state.fullDetail = payload;
    state.isView = false;
  },
  UPDATE_IS_RATING: (state, payload) => {
    state.isRating = payload;
  },
  UPDATE_RATING_DATA: (state, payload) => {
    state.ratingData = payload;
  }
};
const actions = {
  toggleView(context, payload) {
    console.log(payload);
    context.commit("TOGGLE_DETAIL_IMAGE", payload.file);
    if (payload.isFull) {
      context.commit("FULL_DETAIL_IMAGE", payload.isFull);
    } else {
      context.commit("CHANGE_VIEW_IMAGE", payload.status);
    }
  },
  async newLoad(context) {
    context.commit("DELETE_FIRST_MESSAGE");
  },
  async updateQuickButton(context, payload) {
    context.commit("UPDATE_QUICKBUTTON", payload);
  },
  async sendMessage(context, payload) {
    context.commit("REMOVE_CONTENT_IMAGE");
    const user = context.rootGetters["user/user"];
    const userId = hashids.decode(user.id)[0];
    const timestamp = moment().format("X");
    let lennaMessageType = new LennaMessageType({
      messageable_id: userId,
      temporary_id: moment().format("x")
    });
    let message = "";
    switch (payload.type) {
      case "text":
        message = lennaMessageType.text({
          text: payload.text
        });
        break;
      case "image":
        message = lennaMessageType.image({
          fileName: payload.fileName,
          fileUrl: payload.fileUrl
        });
        break;
      case "file":
        message = lennaMessageType.file({
          fileName: payload.fileName,
          fileUrl: payload.fileUrl
        });
        break;
      default:
        break;
    }
    // add user message
    // masking input
    if (context.state.maskingInput) {
      const msg = cloneDeep(message);
      if (msg.content[0].type == "text") {
        /**
         * const expression = /(?<=\d{4})\d(?=\d{4})/gm;
         * const expression = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
         * const expression = /^(\d{4})\d(?=\d{4})|\d(?=\d{4})/gm;
         * const expression = /[0-9](?=([0-9]{4}))/g;
         * const expression = /^[\d-\s]+(?=\d{4})/;
         * const expression = /(\d{4}).*(\d{4})/;
         * */
        // const first4 = msg.content[0].text.substring(0, 4);
        // const last5 = msg.content[0].text.substring(
        //   msg.content[0].text.length - 5
        // );
        // const mask = msg.content[0].text
        //   .substring(4, msg.content[0].text.length - 5)
        //   .replace(/\d/g, "*");
        // msg.content[0].text = `${first4}${mask}${last5}`;
        const expression = /^(\d{4})\d(?=\d{4})|\d(?=\d{4})/gm;
        const result = msg.content[0].text.replace(expression, "*");
        msg.content[0].text = result;
        context.commit("ADD_MESSAGE", msg);
        context.commit("SET_MASKING", false);
      }
    } else {
      context.commit("ADD_MESSAGE", message);
    }
    context.commit("UPDATE_TYPING", true);
    // console.log("ini message nya ",message)
    const cred = encryptor.encrypt(`${user.id}:${user.email}`);
    const credTime = encryptor.encrypt(timestamp);
    let response = await this.$axios.post(
      `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/webhook/webchat`,
      {
        senderId: user.id,
        message: message,
        events: "message",
        integrationId: context.rootGetters.integrationId
      },
      {
        headers: {
          "X-LENNA-WEBCHAT": cred,
          "X-LENNA-ROBOT": credTime
        }
      }
    );
    // console.log('response webhook', response);
    // console.log("response webhook", response)
    context.commit("UPDATE_TYPING", false);
    // update user message with response

    // update room id
    const room_id = response.data.data.user.message.room_id;
    context.commit("UPDATE_ROOM_ID", room_id);
    context.commit("UPDATE_MESSAGE", response.data.data.user);
    // console.log(
    //   "response.data.data.user.isRequestLiveAgent",
    //   response.data.data.user.isRequestLiveAgent
    // );
    context.commit(
      "UPDATE_REQUEST_LIVEAGENT",
      response.data.data.user.isRequestLiveAgent
    );
    // update first id message if isfirst true
    if (state.firstIdMessage == "") {
      context.commit(
        "UPDATE_FIRST_ID_MESSAGE",
        response.data.data.user.message.id
      );
    }
    // add bot message
    let responseBotMessage = [];
    if (!response.data.data.bot.content) {
      responseBotMessage = response.data.data.bot.message.original.data.message;
    } else {
      responseBotMessage = response.data.data.bot.message;
    }
    // console.log('response bot message', responseBotMessage);
    // console.log("response bot", response.data.data)
    if (!isNull(responseBotMessage)) {
      // jika content memiliki array lebih dari satu
      if (size(responseBotMessage.content) > 1) {
        // looping
        forEach(responseBotMessage.content, function(value) {
          let show = {
            show: false
          };
          assign(value, show);
        });
        // handling content when type is image
        var contentImage = [];
        for (var i = 0; i < responseBotMessage.content.length; i++) {
          if (responseBotMessage.content[i].type == "image") {
            contentImage.push(responseBotMessage.content[i]);
            context.commit("ADD_CONTENT_IMAGE", contentImage);
          } else {
            context.commit("REMOVE_CONTENT_IMAGE");
          }
        }
        context.commit("ADD_MESSAGE", responseBotMessage);
      } else {
        context.commit("ADD_MESSAGE", responseBotMessage);
      }
    }
    // add bot quickbutton
    if (
      size(response.data.data.bot.quickbutton) >= 0 &&
      !response.data.data.user.isRequestLiveAgent
    ) {
      context.commit("UPDATE_QUICKBUTTON", response.data.data.bot.quickbutton);
    }
  },
  updateTyping(context, payload) {
    context.commit("UPDATE_TYPING", payload);
  },
  isFirst(context, payload) {
    context.commit("UPDATE_IS_FIRST", payload);
  },
  newMessage(context, payload) {
    // console.log("payload", payload);
    const userWebchat = context.rootGetters["user/user"];
    if (
      (payload.user.id !== hashids.decode(userWebchat.id)[0] &&
        payload.message.messageable_type == "user_platform") ||
      payload.message.messageable_type == "bot"
    ) {
      let message = payload.message;
      // let message = {
      //   messageable: payload.message.messageable,
      //   content: payload.message.content,
      //   created_at: payload.message.created_at,
      //   messageable_id: payload.message.messageable_id,
      //   messageable_type: payload.message.messageable_type,
      //   temporary_id: moment().format("x")
      // };
      context.commit("ADD_MESSAGE", message);
      // } else if (payload.message.messageable_type == "bot") {
      // let message = payload.message;
      // context.commit("ADD_MESSAGE", message);
    }
    // context.commit("UPDATE_MESSAGE", message)
  },
  addMoreMessage(context, payload) {
    // console.log("addMoreMessage", payload);
    forEach(payload, function(o) {
      forEach(o.content, function(value) {
        let show = {
          show: true
        };
        assign(value, show);
      });
    });
    // console.log("ADD_MORE_MESSAGES", payload);
    context.commit("ADD_MORE_MESSAGES", payload);
  },
  async getHistory(context, payload) {
    let response = await this.$axios.post(
      `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/message/get-messages`,
      { id: payload.id },
      {
        params: {
          per_page: 10
        }
      }
    );
    const res = response.data;
    if (res.success) {
      // check is requst live agent
      if (res.data.length > 0) {
        context.commit("UPDATE_FIRST_ID_MESSAGE", res.data[0].id);
        // check is request live agent
        const room_id = res.data[0].room_id;
        let response_is_unserved_room = await this.$axios.post(
          `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/livechat/check-status`,
          { room_id: room_id }
        );
        // console.log(
        //   "response_is_unserved_room.data.data.current_status_livechat 2",
        //   response_is_unserved_room.data.data.current_status_livechat
        // );
        context.commit(
          "UPDATE_REQUEST_LIVEAGENT",
          response_is_unserved_room.data.data.current_status_livechat
        );
        context.commit("ADD_MORE_MESSAGES", res.data);
      }
    } else {
      context.commit("ADD_MORE_MESSAGES", []);
    }
    context.rootState.other.enableScroll = true;
    context.rootState.other.triggerScroll = true;
  },
  // async getHistory(context, payload) {
  //   let response = await this.$axios.post(
  //     `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/message/get-messages`,
  //     { id: payload.id },
  //     {
  //       params: {
  //         page: payload.page,
  //         per_page: 10
  //       }
  //     }
  //   );
  //   const res = response.data;
  //   if (res.success) {
  //     // check is requst live agent
  //     if (res.data.data.length > 0) {
  //       context.commit("UPDATE_FIRST_ID_MESSAGE", res.data.data[0].id);
  //       // check is request live agent
  //       const room_id = response.data.data.data[0].room_id;
  //       let response_is_unserved_room = await this.$axios.post(
  //         `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/livechat/check-status`,
  //         { room_id: room_id }
  //       );
  //       context.commit(
  //         "UPDATE_REQUEST_LIVEAGENT",
  //         response_is_unserved_room.data.data.current_status_livechat
  //       );
  //     }
  //     context.commit("ADD_MORE_MESSAGES", response.data.data.data);
  //   } else {
  //     context.commit("ADD_MORE_MESSAGES", []);
  //   }
  //   context.rootState.other.enableScroll = true;
  //   context.rootState.other.triggerScroll = true;
  // },
  async updatePage(context, payload) {
    context.commit("ADD_PAGE", payload);
  },
  updateFirstMessageId(context, payload) {
    context.commit("UPDATE_FIRST_ID_MESSAGE", payload);
  },
  removeMessageTypeFormAfterSubmit({ commit }) {
    commit("REMOVE_MESSAGE_TYPE_FORM_AFTER_SUBMIT");
  },
  async cancelLiveAgent(context) {
    const webchatUser = localStorage.webchat_user;
    if (webchatUser) {
      const userId = JSON.parse(localStorage.webchat_user).id;
      // let userId = "";
      // if (isNaN(localStorageUserId)) {
      // const userId = hashids.decode(localStorageUserId)[0];
      // } else {
      // userId = localStorageUserId;
      // }
      await this.$axios.post(
        `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/livechat/cancel`,
        {
          user_id: userId
        }
      );
      context.commit("UPDATE_QUICKBUTTON", []);
    }
  },
  queueLiveChat(context, payload) {
    const user = context.rootGetters["user/user"];
    // console.log("payload", payload);
    let queue = toArray(payload.queue);
    queue = sortBy(queue, function(o) {
      return o.request_at;
    });
    // console.log("queue", queue);
    // console.log("user.id", user.id);
    let indexQueue = queue.findIndex(function(o) {
      return o.user_id == user.id;
    });
    // console.log("indexQueue", indexQueue);
    if (payload.user.id == user.id) {
      localStorage.lenna_queue = JSON.stringify({
        state: true,
        index: indexQueue
      });
      context.commit("SET_QUEUE", {
        state: true,
        index: indexQueue
      });
    } else {
      localStorage.lenna_queue = JSON.stringify({
        state: false,
        index: null
      });
      context.commit("SET_QUEUE", {
        state: false,
        index: null
      });
    }
  },
  async checkLivechat(context) {
    const webchatUser = localStorage.webchat_user;
    if (webchatUser) {
      const userId = JSON.parse(localStorage.webchat_user).id;
      // const userId = "";
      // if (isNaN(localStorageUserId)) {
      // userId = hashids.decode(localStorageUserId)[0];
      // }
      let response = await this.$axios.post(
        `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/livechat/check`,
        {
          user_id: userId
        }
      );

      let is_requested = response.data;
      context.commit("UPDATE_REQUEST_LIVEAGENT", is_requested);
    }
  },

  async checkQueue(context) {
    const webchatUser = localStorage.webchat_user;
    if (webchatUser) {
      try {
        let response = await this.$axios.get(
          `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/queue/check`
        );
        const localStorageUserId = JSON.parse(localStorage.webchat_user).id;
        // let userId = "";
        // if (isNaN(localStorageUserId)) {
        const userId = hashids.decode(localStorageUserId)[0];
        // } else {
        //   userId = localStorageUserId;
        // }
        let res = toArray(response.data.data);
        let someUser = some(res, function(o) {
          return o.user_id == userId;
        });
        let idx = res.findIndex(function(o) {
          return o.user_id == userId;
        });
        if (response.data.success) {
          if (someUser) {
            localStorage.lenna_queue = JSON.stringify({
              state: true,
              index: idx
            });
            context.commit("SET_QUEUE", {
              state: true,
              index: idx
            });
          } else {
            localStorage.lenna_queue = JSON.stringify({
              state: false,
              index: null
            });
            context.commit("SET_QUEUE", {
              state: false,
              index: null
            });
          }
        } else {
          localStorage.lenna_queue = JSON.stringify({
            state: false,
            index: null
          });
          context.commit("SET_QUEUE", {
            state: false,
            index: null
          });
        }
      } catch (error) {
        console.log("queue error", error);
      }
    }
  },
  handleQueueLiveChat(context, payload) {
    const user = context.rootGetters["user/user"];
    // console.log("payload", payload);
    const queue = toArray(payload.unserved_room);
    // console.log("queue", queue);
    // console.log("user.id", user.id);
    let indexQueue = queue.findIndex(function(o) {
      return o.created_by.id == user.id;
    });
    // console.log("indexQueue", indexQueue);
    if (indexQueue == -1) {
      localStorage.lenna_queue = JSON.stringify({
        state: false,
        index: null
      });
      return context.commit("SET_QUEUE", {
        state: false,
        index: null
      });
    }
    if (payload.room.created_by.id !== user.id) {
      localStorage.lenna_queue = JSON.stringify({
        state: true,
        index: indexQueue
      });
      context.commit("SET_QUEUE", {
        state: true,
        index: indexQueue
      });
    } else {
      // localStorage.lenna_queue = JSON.stringify({
      //   state: false,
      //   index: null
      // });
      // context.commit("SET_QUEUE", {
      //   state: false,
      //   index: null
      // });
    }
  },
  requestQueueLiveChat(context, payload) {
    const user = context.rootGetters["user/user"];
    // console.log("payload", payload);
    let queue = toArray(payload.queue);
    queue = sortBy(queue, function(o) {
      return o.request_at;
    });
    // console.log("queue", queue);
    // console.log("user.id", user.id);
    const indexQueue = queue.findIndex(function(o) {
      return o.created_by.id == user.id;
    });
    // console.log("indexQueue", indexQueue);
    if (indexQueue == -1) {
      localStorage.lenna_queue = JSON.stringify({
        state: false,
        index: null
      });
      return context.commit("SET_QUEUE", {
        state: false,
        index: null
      });
    }
    if (payload.room.created_by.id == user.id) {
      localStorage.lenna_queue = JSON.stringify({
        state: true,
        index: indexQueue
      });
      context.commit("SET_QUEUE", {
        state: true,
        index: indexQueue
      });
    } else {
      localStorage.lenna_queue = JSON.stringify({
        state: false,
        index: null
      });
      context.commit("SET_QUEUE", {
        state: false,
        index: null
      });
    }
  },
  async getParamQueue(context, payload) {
    const response = await this.$axios.post(
      `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/general-value/get-by-key`,
      {
        key: payload.key
      }
    );
    if (response.data.success) {
      if (isNull(response.data.data)) {
        const result = {
          type: payload.type,
          value: 3
        };
        context.commit("SET_QUEUE_PARAM", result);
      } else {
        const result = {
          type: payload.type,
          value: response.data.data.value
        };
        context.commit("SET_QUEUE_PARAM", result);
      }
    }
  },
  maskingInput(context, payload) {
    context.commit("SET_MASKING", payload);
  }
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
};
